<template>
  <div class="auth-page-wrapper">
    <!-- Logo -->
    <img src="../../assets/icons/x2/img_billy_logo@2x.png" class="auth-logo" alt="Logo"/>

    <h1 class="page-title">Forgot your password?</h1>
    <p class="page-subtitle">Please enter the email address you use to sign in and we will send you a reset link.</p>

    <div class="forgot-form">
      <FormWrapper :send-form-data="validateForm" :mode="3">
        <template #form-content>

          <!-- E-mail field -->
          <BasicInputField field-title="Email address"
                           field-name="email"
                           rules="required"
                           type="email"
                           placeholder="Enter email address"
                           cy-selector="email-input"
                           :icon="emailIcons"
                           class="login-input"/>
        </template>

        <!-- Submit button -->
        <template #button-submit="{isInvalid}">
          <button :disabled="isInvalid"
                  :class="{'button-disabled': isInvalid}"
                  type="submit"
                  class="button-main auth-button button-create">
            Send reset link
          </button>
        </template>

      </FormWrapper>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/composition-api"
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputIcons from "@/models/input/InputIcons";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import BasicInputField from "@/components/yo-form/BasicInputField";
import {SEND_FORGOT_PASSWORD} from "@/store/auth/actions";

export default {
  name: "ForgotPassword",
  components: {
    BasicInputField,
    FormWrapper,
  },
  setup(_, {root}) {
    const store = root.$store;
    const router = root.$router;

    /** Input icons **/
    const isLoading = computed(() => store.getters.isPageLoading);

    /** Form validation **/
    function validateForm(formData) {
      sendForgotPassword(formData).then(response => {
        if (response) {
          router.push({name: ROUTE_NAMES_AUTH.LOGIN});
        }
      });
    }

    /** Forgot password **/
    function sendForgotPassword(formData) {
      return store.dispatch(SEND_FORGOT_PASSWORD, formData);
    }

    /** Input icons **/
    const emailIcons = new InputIcons(require('../../assets/icons/svg/ic_user_active.svg'), require('../../assets/icons/svg/ic_user_inactive.svg'), require('../../assets/icons/svg/ic_user_error.svg'));
    const passwordIcons = new InputIcons(require('../../assets/icons/svg/ic_password_active.svg'), require('../../assets/icons/svg/ic_password_inactive.svg'), require('../../assets/icons/svg/ic_password_error.svg'));

    /** Errors **/
    const authError = computed(() => store.getters.getAuthError);

    return {
      /** Loading **/
      isLoading,

      /** Form validation **/
      validateForm,

      /** Input icons **/
      emailIcons,
      passwordIcons,

      /** Errors **/
      authError
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.page {
  &-title {
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  &-subtitle {
    font-size: rem(16);
    margin-bottom: rem(35);
  }
}

.button-create {
  margin-top: rem(40);
}
</style>
