<template>
  <div :style="{'width': fieldWidth}" class="form-group">
    <h3 v-if="fieldTitle" class="form-input-title">{{ fieldTitle }}</h3>
    <label :for="fieldName" class="input-label">
      <input ref="inputField"
             :type="type"
             :id="fieldName"
             :name="fieldName"
             :class="[{'field-error': apiError || errorMessages.length > 0}, {'without-icon': !displayedIcon}]"
             :placeholder="placeholder"
             :data-cy="cySelector || `${fieldName}-input`"
             @input="handleInput"
             @focus="() => updateDisplayedIcon('active')"
             @focusout="() => updateDisplayedIcon('inactive')"
             v-model="input"
             :readonly="isReadOnly"
             :maxlength="maxLength"
             :disabled="isReadOnly"
             class="form-input"/>

      <!-- Input icon - There are 3 states: inactive, active and error. -->
      <img v-if="displayedIcon" :src="displayedIcon" :class="{'has-error': errorMessages.length > 0}" class="input-icon"
           alt=""/>
    </label>
    <div v-show="apiError || errorMessages.length > 0">
      <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
      <p v-if="apiError" class="text-error">{{ apiError }}</p>
      <p v-else class="text-error">{{errorMessages[0]}}</p>
<!--      <p v-else v-for="(error, index) of errorMessages" :key=index class="text-error">{{ error }}</p>-->
    </div>
  </div>
</template>

<script>
/** here keep it for Vue 3 **/
// import {ref, watch} from 'vue';
import {ref, watch} from '@vue/composition-api';
import Validator from "@/utils/yo-validator/YoValidator";

export default {
  props: {
    // required
    fieldTitle: {
      required: true
    },
    fieldName: {
      required: true
    },
    type: {
      required: true
    },
    // not required
    apiErrorText: {
      required: false,
      type: String
    },
    value: {
      required: false
    },
    placeholder: {
      required: false
    },
    rules: {
      default: 'required'
    },
    fieldWidth: {
      default: '100%'
    },
    debounce: {
      type: Number,
      required: false,
      default: 0
    },
    callback: {
      required: false
    },
    cySelector: {
      type: String,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    displayErrors: {
      type: Boolean,
      required: false,
      default: true
    },
    maxLength: {
      type: Number,
      required: false
    },
    icon: {
      // type: String,
      required: false,
    },
  },
  setup(props) {
    /** validator returned error messages **/
    const {errorMessages, handleInput, setFieldData} = Validator.register(props);

    /** watch api error text from parent **/
    const apiError = ref('');
    watch(() => props.apiErrorText, (newVal) => {
      apiError.value = newVal.trim();
    });

    /** on created **/
    const input = ref('');
    (function setValueOnCreate() {
      if (props.value) {
        input.value = props.value;
        setFieldData(props.value);
      }
    })();

    watch(() => props.value, (newVal) => {
      if (props.isReadOnly) {
        input.value = props.value;
        setFieldData(props.value);
      }
    });

    /** Icon **/
    watch(errorMessages, (newVal) => {
      if (newVal.length) {
        updateDisplayedIcon('error');
      }
    });

    const displayedIcon = ref(props.icon?.inactive);

    function updateDisplayedIcon(icon) {
      if (props.icon) {
        displayedIcon.value = props?.icon[icon];
      }
    }

    return {
      errorMessages,
      handleInput,
      apiError,
      input,

      /** Icon **/
      displayedIcon,
      updateDisplayedIcon
    }
  }
}
</script>

<style scoped lang="scss">
</style>
